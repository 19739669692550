import React from 'react';
import { useAuth } from '../context/AuthContext';

const Settings = () => {
  const { user, creditBalance } = useAuth();

  return (
    <div className="page-container">
      <div className="settings-card">
        <h1>Settings</h1>
        
        <section className="settings-section">
          <h2>Account Information</h2>
          <div className="info-group">
            <label>Email</label>
            <div className="info-value">{user?.email}</div>
          </div>
          <div className="info-group">
            <label>Credit Balance</label>
            <input
                    className="w-250 bg-gray-100"
                    type="text"
                    id="credit-balance"
                    name="credit_balance"
                    value={creditBalance}
                    readOnly
                    style={{ cursor: 'not-allowed' }}
                />
          </div>
        </section>

        
      </div>
    </div>
  );
};

export default Settings;
