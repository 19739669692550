import React from 'react';
import './partial-einvoisform-lineitems.css';

const InvoiceLineItems = ({ lineItems, handleLineItemChange, addLineItem, removeLineItem }) => {
    return (
        <div className="form-card">
            <h3>Invoice Line Items</h3>
            <div className="line-items-headers">
                <div>Classification</div>
                <div>Desc</div>
                <div>Qty</div>
                <div>Unit Price</div>
                <div>Total</div>
                <div>Tax Amount</div>
                <div></div>
            </div>
            <div id="line-items">
                {lineItems.map((item, index) => (
                    <div key={index} className={`line-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <div className="line-item-grid">
                            <div className="input-group">
                                <label className="mobile-label">Classification</label>
                                <input
                                    type="text"
                                    name="item_classification"
                                    value={item.classification}
                                    onChange={(e) => handleLineItemChange(index, 'classification', e.target.value)}
                                    placeholder="008"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label className="mobile-label">Description</label>
                                <input
                                    type="text"
                                    name="item_description"
                                    value={item.description}
                                    onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                                    placeholder="Laptop Peripherals"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label className="mobile-label">Quantity</label>
                                <input
                                    type="number"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) => handleLineItemChange(index, 'quantity', parseFloat(e.target.value))}
                                    step="1"
                                    placeholder="1"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label className="mobile-label">Unit Price</label>
                                <input
                                    type="number"
                                    name="unit_price"
                                    value={item.unit_price}
                                    onChange={(e) => handleLineItemChange(index, 'unit_price', parseFloat(e.target.value))}
                                    step="0.01"
                                    placeholder="1436.50"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label className="mobile-label">Total</label>
                                <input
                                    type="number"
                                    name="total"
                                    value={item.total}
                                    readOnly
                                    className="readonly-input"
                                />
                            </div>
                            <div className="input-group">
                                <label className="mobile-label">Tax Amount</label>
                                <input
                                    type="number"
                                    name="tax_amount"
                                    value={item.tax_amount}
                                    readOnly
                                    className="readonly-input"
                                />
                            </div>
                            <button 
                                type="button" 
                                onClick={() => removeLineItem(index)}
                                className="remove-button"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <button 
                type="button" 
                onClick={addLineItem}
                className="add-button"
            >
                Add Line Item
            </button>
        </div>
    );
};

export default InvoiceLineItems;
