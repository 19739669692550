import React from 'react';

const InvoiceFinalTotals = ({ formData, totals }) => (
    <div className="flexbox">
        { /*<h3>Payment Information</h3> */ }
        <div>
        <label htmlFor="payment-method">Payment Method:</label>
        <select id="payment-method" name="payment_method" required>
            <option value="01">Cash</option>
            <option value="02">Cheque</option>
            <option value="03">Bank Transfer</option>
            <option value="04">Credit Card</option>
            <option value="05">Debit Card</option>
            <option value="06">e-Wallet / Digital Wallet</option>
            <option value="07">Digital Bank</option>
            <option value="08">Others</option>
        </select>
        </div>
        <div>
            <div className="form-group rightalign">
                <label htmlFor='total-before-tax'>Total Before Tax:</label>
                <input
                    className="small"
                    style={{ textAlign:"right" }}
                    id="total-before-tax" name="total_before_tax" 
                    type="number"
                    value={totals.totalBeforeTax.toFixed(2)}
                    readOnly
                />
            </div>
            <div className="form-group rightalign">
                <label htmlFor='total-tax-amount'>Total Tax Amount:</label>
                <input
                    className="small"
                    style={{ textAlign:"right" }}
                    id="total-tax-amount" name="total_tax_amount" 
                    type="number"
                    value={totals.totalTaxAmount.toFixed(2)}
                    readOnly
                />
            </div>
            <div className="form-group rightalign">
                <label htmlFor='total-after-tax'>Total After Tax:</label>
                <input
                    className="small"
                    style={{ textAlign:"right" }}
                    type="number"
                    id="total-after-tax" name="total_after_tax" 
                    value={totals.totalAfterTax.toFixed(2)}
                    readOnly
                />
            </div>
         </div>
    </div>
);

export default InvoiceFinalTotals;
