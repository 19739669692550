import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState(0);

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserMetadata(session.user.id);
        // Store session data
        sessionStorage.setItem('userEmail', session.user.email);
        sessionStorage.setItem('userId', session.user.id);
      }
      setLoading(false);
    });

    // Listen for changes on auth state
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserMetadata(session.user.id);
        // Store session data
        sessionStorage.setItem('userEmail', session.user.email);
        sessionStorage.setItem('userId', session.user.id);
      } else {
        // Clear session data on sign out
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('creditBalance');
      }
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchUserMetadata = async (userId) => {
    const { data } = await supabase
      .from('user_metadata')
      .select('credit_balance')
      .eq('user_id', userId)
      .single();

    if (data) {
      setCreditBalance(data.credit_balance);
      // Store credit balance in session storage
      sessionStorage.setItem('creditBalance', data.credit_balance.toString());
    }
  };

  const signUp = async (email, password) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });
    return { data, error };
  };

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    return { data, error };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    // Clear session storage on sign out
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('creditBalance');
    return { error };
  };

  const resetPassword = async (email) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);
    return { data, error };
  };

  // Helper functions to get session data
  const getSessionEmail = () => sessionStorage.getItem('userEmail');
  const getSessionUserId = () => sessionStorage.getItem('userId');
  const getSessionCreditBalance = () => sessionStorage.getItem('creditBalance');

  const value = {
    user,
    creditBalance,
    signUp,
    signIn,
    signOut,
    resetPassword,
    loading,
    getSessionEmail,
    getSessionUserId,
    getSessionCreditBalance
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
