import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../context/AuthContext';
import InvoiceMainInfo from './partial-einvoisform-maininfo';
import InvoiceTaxInfo from './partial-einvoisform-taxinfo';
import InvoiceSupplierInfo from './partial-einvoisform-supplierinfo';
import InvoiceCustomerInfo from './partial-einvoisform-customerinfo';
import InvoiceLineItems from './partial-einvoisform-lineitems';
import InvoiceFinalTotals from './partial-einvoisform-finaltotals';

const GenerateEinvois = () => {

    const { user } = useAuth();
    const [formData, setFormData] = useState({
        invoice_id: 'INV123456',
        issue_date: new Date().toISOString().split('T')[0],
        currency: 'MYR',
        global_tax_category: 'S',
        global_tax_percentage: '8',
        supplier_name: 'RECOMN TECHNOLOGIES SDN. BHD.',
        supplier_tin: 'C24276483050',
        supplier_biz_id_type: 'BRN',
        supplier_biz_id_value: '202001234567',
        supplier_sst_no: 'A01-2345-67891012',
        supplier_msic_code: '63990',
        supplier_business_activity_desc: '',
        supplier_address: 'A308 Phileo Damansara 1, PJ 63520',
        supplier_state: '10',
        supplier_city: 'PETALING JAYA',
        supplier_country: 'MYS',
        supplier_tel: '+60120000000',
        customer_name: 'RECOMN INTERIORS SDN. BHD.',
        customer_tin: 'C25338011010',
        customer_biz_id_type: 'BRN',
        customer_biz_id_value: '201801004813',
        customer_sst_no: 'W00-2000-32000000',
        customer_address: '20 Jalan Buyer',
        customer_city: 'PETALING JAYA',
        customer_state: '10',
        customer_country: 'MYS',
        customer_tel: '+60129999999',
        payment_method: '01',
        line_items: [
            {
                classification: '008',
                description: '',
                quantity: 1,
                unit_price: 0,
                total: 0,
                tax_amount: 0
            }
        ]
    });

    const [totals, setTotals] = useState({
        totalBeforeTax: 0,
        totalTaxAmount: 0,
        totalAfterTax: 0
    });

    const [xmlResult, setXmlResult] = useState('');
    const [base64Result, setBase64Result] = useState('');
    const [submitResult, setSubmitResult] = useState('');
    const [documentDetails, setDocumentDetails] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const calculateTotals = useCallback(() => {
        const taxPercentage = parseFloat(formData.global_tax_percentage) || 0;
        let totalBeforeTax = 0;
        let totalTaxAmount = 0;

        formData.line_items.forEach(item => {
            totalBeforeTax += item.total;
            totalTaxAmount += item.tax_amount;
        });

        setTotals({
            totalBeforeTax,
            totalTaxAmount,
            totalAfterTax: totalBeforeTax + totalTaxAmount
        });
    }, [formData.line_items, formData.global_tax_percentage]);

    useEffect(() => {
        calculateTotals();
    }, [calculateTotals]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleLineItemChange = (index, field, value) => {
        const newLineItems = [...formData.line_items];
        const item = { ...newLineItems[index], [field]: value };
        
        if (field === 'quantity' || field === 'unit_price') {
            const quantity = field === 'quantity' ? value : item.quantity;
            const unitPrice = field === 'unit_price' ? value : item.unit_price;
            const total = quantity * unitPrice;
            const taxPercentage = parseFloat(formData.global_tax_percentage) || 0;
            const taxAmount = (total * taxPercentage) / 100;
            
            item.total = parseFloat(total.toFixed(2));
            item.tax_amount = parseFloat(taxAmount.toFixed(2));
        }

        newLineItems[index] = item;
        setFormData(prev => ({
            ...prev,
            line_items: newLineItems
        }));
    };

    const addLineItem = () => {
        setFormData(prev => ({
            ...prev,
            line_items: [...prev.line_items, {
                classification: '008',
                description: '',
                quantity: 1,
                unit_price: 0,
                total: 0,
                tax_amount: 0
            }]
        }));
    };

    const removeLineItem = (index) => {
        if (formData.line_items.length > 1) {
            const newLineItems = formData.line_items.filter((_, i) => i !== index);
            setFormData(prev => ({
                ...prev,
                line_items: newLineItems
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        try {
            const response = await fetch('/api/einvois/generateubl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/xml'
                },
                body: JSON.stringify({
                    ...formData,
                    total_before_tax: totals.totalBeforeTax,
                    total_tax_amount: totals.totalTaxAmount,
                    total_after_tax: totals.totalAfterTax
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/xml')) {
                throw new Error('Received invalid response format from server');
            }

            const xmlresult = await response.text();
            if (!xmlresult) {
                throw new Error('Received empty response from server');
            }

            setXmlResult(xmlresult);
            setBase64Result(btoa(xmlresult));
            setError('');
        } catch (err) {
            console.error('Error generating XML:', err);
            setError(`Failed to generate XML: ${err.message}`);
            setXmlResult('');
            setBase64Result('');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDocumentDetails = async (uuid) => {
        try {
            const response = await fetch(`/api/einvois/document-details/${uuid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch document details: ${response.status}`);
            }

            const result = await response.json();
            if (result.status === 'success' && result.data) {
                setDocumentDetails(result.data);
            } else {
                throw new Error('Invalid response format from document details API');
            }
        } catch (err) {
            console.error('Error fetching document details:', err);
            setError(`Failed to fetch document details: ${err.message}`);
        }
    };

    const handleSubmitToLHDN = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setDocumentDetails(null);
        try {
            const response = await fetch('/api/einvois/submit-einvoice-document', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    documentXml: xmlResult,
                    invoice_id: formData.invoice_id
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            const result = await response.json();
            setSubmitResult(JSON.stringify(result, null, 2));
            setError('');
        } catch (err) {
            console.error('Error submitting to LHDN:', err);
            setError(`Failed to submit to LHDN: ${err.message}`);
            setSubmitResult('');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetDocumentDetails = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setDocumentDetails(null);
        try {
            const submitResultObj = JSON.parse(submitResult);
            if (submitResultObj.status !== 'success' || !submitResultObj.data?.acceptedDocuments?.[0]?.uuid) {
                throw new Error('Invalid submission result or missing document uuid');
            }

            await fetchDocumentDetails(submitResultObj.data.acceptedDocuments[0].uuid);
            setError('');
        } catch (err) {
            console.error('Error getting document details:', err);
            setError(`Failed to get document details: ${err.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="page-container">
            <div className="card">
                <h1>Generate Malaysia eInvoice</h1>
                <p>If you run a business in Malaysia, you need to send all your invoice data to LHDN's einvoicing system. The einvoice data must be sent in a very specific XML format (adapted from the <a href="https://docs.peppol.eu/poac/my/" traget="_blank">EU PEPPOL standard</a>)</p>
                <p>This tool helps businesses generate a properly-formatted Malaysia eInvoice, also sends it to LHDN for validation. And for developers, it shows the XML content for reference</p>
                <hr></hr>

                <form onSubmit={handleSubmit}>
                    <InvoiceMainInfo
                        formData={formData}
                        handleInputChange={handleInputChange} 
                    />
                    <InvoiceTaxInfo
                        formData={formData}
                        handleInputChange={handleInputChange} 
                    />
                    <InvoiceSupplierInfo
                        formData={formData}
                        handleInputChange={handleInputChange} 
                    />
                    <InvoiceCustomerInfo
                        formData={formData}
                        handleInputChange={handleInputChange} 
                    />
                    <InvoiceLineItems
                        lineItems={formData.line_items}
                        handleLineItemChange={handleLineItemChange}
                        addLineItem={addLineItem}
                        removeLineItem={removeLineItem}
                    />
                    <InvoiceFinalTotals
                        totals={totals}
                        handleInputChange={handleInputChange} 
                    />

                    <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Generating...' : 'Generate XML'}
                    </button>
                </form>

                {error && <div className="error-message" role="alert">{error}</div>}

                {xmlResult && (
                    <div>
                        <h2>Generated XML</h2>
                        <p>Below is the XML data that eInvois system expects to receive. All the data that you input in the form above has been formatted according to LHDN structure</p>
                        <textarea
                            value={xmlResult}
                            readOnly
                            style={{ width: '100%', height: '200px' }}
                        />
                        <h3>Base64 Encoded</h3>
                        <p>Before sending the XML data to eInvois, it needs to be converted to Base64 as below</p>
                        <textarea
                            value={base64Result}
                            readOnly
                            style={{ width: '100%', height: '100px' }}
                        />
                        <form onSubmit={handleSubmitToLHDN}>
                            <p>Now that the above XML has been populated, you can click the button below to package up the documents and send to LHDN. Remember that you should already have applied for a client id and client secret that allows you to connect to the myinvois staging server </p>
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? 'Submitting...' : 'Submit to LHDN'}
                            </button>
                        </form>
                    </div>
                )}

                {submitResult && (
                    <div>
                        <h2>Submission Result</h2>
                        <p>Read the submission result below to see if any errors occurred.</p>
                        <textarea
                            value={submitResult}
                            readOnly
                            style={{ width: '100%', height: '100px' }}
                        />

                        <form onSubmit={handleGetDocumentDetails}>
                            <p>If the submission was successful, you can click the button below to get the link from LHDN to the validated invoice</p>
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? 'Getting...' : 'Get Invoice Link'}
                            </button>
                        </form>
                        
                        {documentDetails?.shareableLink && (
                            <div className="mt-4">
                                <h3>Document Link</h3>
                                <p>Click the link below to view your validated document:</p>
                                <a 
                                    href={documentDetails.shareableLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary"
                                >
                                    View Document
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GenerateEinvois;
