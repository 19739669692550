import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const tools = [
    {
      id: 1,
      title: 'QR Code Generator',
      description: 'Generate QR codes for URLs, text, or contact information',
      icon: '🔲',
      comingSoon: true
    },
    {
      id: 2,
      title: 'UBL XML Invoice Generator',
      description: 'Create standardized UBL XML format invoices',
      icon: '📄',
      comingSoon: false,
      route: '/einvois/generate-einvois'
    },
    {
      id: 3,
      title: 'AI Image Generator',
      description: 'Generate unique images using artificial intelligence',
      icon: '🎨',
      comingSoon: true
    }
  ];

  return (
    <div className="page-container">
      <div className="home-header">
        <h1>Online Tools Directory</h1>
        <p>A collection of useful tools to help streamline your workflow</p>
      </div>
      
      <div className="tools-grid">
        {tools.map(tool => (
          <div key={tool.id} className="tool-card">
            <div className="tool-icon">{tool.icon}</div>
            <h2>{tool.title}</h2>
            <p>{tool.description}</p>
            {tool.comingSoon ? (
              <span className="coming-soon-badge">Coming Soon</span>
            ) : (
              <Link to={tool.route}>
                <button className="try-now-button">Try Now</button>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
