import React from 'react';

const InvoiceMainInfo = ({ formData, handleInputChange, errors }) => {
    return (
        <div>
            <h3>Invoice Details</h3>
            <div className="flexbox">
            
            <div className="form-group">
                <label htmlFor="invoice-id">Invoice ID:</label>
                <input
                    className="medium"
                    type="text"
                    id="invoice-id"
                    name="invoice_id"
                    value={formData.invoice_id}
                    onChange={handleInputChange}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="issue-date">Issue Date:</label>
                <input
                    className="small"
                    type="date"
                    id="issue-date"
                    name="issue_date"
                    value={formData.issue_date}
                    onChange={handleInputChange}
                    required
                />
            </div>

            <div className="form-group" style={{ display:"none" }}>
                <label htmlFor="currency">Currency Code:</label>
                <select id="currency" name="currency" value={formData.currency} style={{ width:"350px" }} onChange={handleInputChange}>
                    <option value="">Select currency</option>
                    <option value="MYR">MYR (Malaysian Ringgit)</option>
                    <option value="USD">USD (US Dollar)</option>
                </select>
            </div>
        </div>
        </div>
    );
};

export default InvoiceMainInfo;
