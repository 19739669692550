import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../utils/supabaseClient';

const Billing = () => {
  const { user, creditBalance } = useAuth();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    document.title = 'Billing | FlowGolem';
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const { data, error } = await supabase
        .from('product')
        .select('id, stripe_product_id, product_name, credits, stripe_price_id, price, stripe_payment_link')
        .eq('status', 'enabled');

      if (error) throw error;
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  const handlePurchase = (paymentLink) => {
    if (!paymentLink) {
      alert('Payment link not available. Please contact support.');
      return;
    }
    // Only append email for prefill in Stripe checkout
    const url = new URL(paymentLink);
    url.searchParams.append('prefilled_email', user.email);
    window.location.href = url.toString();
  };

  return (
    <div className="page-container">
      <h1>Billing</h1>
      
      <div className="card">
        <h2>Your Credit Balance</h2>
        <p>{creditBalance} Credits</p>
      </div>

      <div className="card" style={{marginTop: '2rem'}}>
        <h2>Add More Credits</h2>
        <div className="tools-grid">
          {products.map(product => (
            <div key={product.id} className="tool-card">
              <h3>{product.product_name}</h3>
              <p>{product.credits} Credits</p>
              <p>SGD {product.price.toFixed(2)}</p>
              <button 
                onClick={() => handlePurchase(product.stripe_payment_link)}
                disabled={!product.stripe_payment_link}
              >
                Buy Now
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Billing;
