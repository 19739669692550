import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Sidebar = ({ isOpen, onClose }) => {
  const { user, creditBalance, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <>
      <div className={`sidebar-overlay ${isOpen ? 'active' : ''}`} onClick={onClose}></div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>FlowGolem</h2>
          {/*
          <button className="close-sidebar" onClick={onClose}>×</button>
          */}
        </div>
        
        <nav className="sidebar-nav">
          <Link to="/" className="nav-item" onClick={onClose}>
            Home
          </Link>
          
          {user ? (
            <>
              <Link to="/settings" className="nav-item" onClick={onClose}>
                Settings
              </Link>
              <Link to="/billing" className="nav-item credit-balance" onClick={onClose}>
                Credits: {creditBalance}
              </Link>
              <button onClick={handleLogout} className="nav-item logout-btn">
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="nav-item" onClick={onClose}>
              Login
            </Link>
          )}
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
