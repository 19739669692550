import React from 'react';

const InvoiceCustomerInfo = ({ formData, handleInputChange }) => (
    <div className="form-card">
        <h3>Customer Information</h3>
        
        <div className="form-group">
            <label htmlFor="customer-name">Customer Name:</label>
            <input
                type="text"
                id="customer-name"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleInputChange}
                placeholder="RECOMN INTERIORS SDN. BHD."
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-tin">Customer Tax ID (TIN):</label>
            <input
                type="text"
                id="customer-tin"
                name="customer_tin"
                value={formData.customer_tin}
                onChange={handleInputChange}
                placeholder="C25338011010"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-biz-id-type">Customer ID type (E.g. SSM, Passport):</label>
            <input
                type="text"
                id="customer-biz-id-type"
                name="customer_biz_id_type"
                value={formData.customer_biz_id_type}
                onChange={handleInputChange}
                placeholder="BRN"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-biz-id-value">Customer ID value (E.g. SSM num, Passport num):</label>
            <input
                type="text"
                id="customer-biz-id-value"
                name="customer_biz_id_value"
                value={formData.customer_biz_id_value}
                onChange={handleInputChange}
                placeholder="e.g. 201801004813"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-sst-no">Customer SST No. (if not SST registered, put "NA"):</label>
            <input
                type="text"
                id="customer-sst-no"
                name="customer_sst_no"
                value={formData.customer_sst_no}
                onChange={handleInputChange}
                placeholder="e.g. W00-2000-32000000"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-address">Customer Address:</label>
            <textarea
                id="customer-address"
                name="customer_address"
                value={formData.customer_address}
                onChange={handleInputChange}
                placeholder="e.g. Lot 66, Bangunan Merdeka, Persiaran Jaya, Kuala Lumpur, 50480"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-city">Customer City:</label>
            <input
                type="text"
                id="customer-city"
                name="customer_city"
                value={formData.customer_city}
                onChange={handleInputChange}
                placeholder="PETALING JAYA"
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-state">Customer State:</label>
            <select
                id="customer-state"
                name="customer_state"
                value={formData.customer_state}
                onChange={handleInputChange}
                required
            >
                <option value="01">Johor</option>
                <option value="02">Kedah</option>
                <option value="03">Kelantan</option>
                <option value="04">Melaka</option>
                <option value="05">Negeri Sembilan</option>
                <option value="06">Pahang</option>
                <option value="07">Pulau Pinang</option>
                <option value="08">Perak</option>
                <option value="09">Perlis</option>
                <option value="10">Selangor</option>
                <option value="11">Terengganu</option>
                <option value="12">Sabah</option>
                <option value="13">Sarawak</option>
                <option value="14">Wilayah Persekutuan Kuala Lumpur</option>
                <option value="15">Wilayah Persekutuan Labuan</option>
                <option value="16">Wilayah Persekutuan Putrajaya</option>
                <option value="17">Not Applicable</option>
            </select>
        </div>

        <div className="form-group">
            <label htmlFor="customer-country">Customer Country:</label>
            <input
                type="text"
                id="customer-country"
                name="customer_country"
                value={formData.customer_country}
                onChange={handleInputChange}
                placeholder="MYS"
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="customer-tel">Customer Tel:</label>
            <input
                type="text"
                id="customer-tel"
                name="customer_tel"
                value={formData.customer_tel}
                onChange={handleInputChange}
                placeholder="+60129999999"
                required
            />
        </div>
    </div>
);

export default InvoiceCustomerInfo;
