import React, { useEffect, useState } from 'react';

const InvoiceSupplierInfo = ({ formData, handleInputChange }) => {
    const [msicCodeMap, setMsicCodeMap] = useState(new Map());

    useEffect(() => {
        fetch('/data/MSICSubCategoryCodes.json')
            .then(response => response.json())
            .then(data => {
                // Create the code-description mapping
                const codeMap = new Map();
                data.forEach(item => {
                    codeMap.set(item.Code, item.Description);
                });
                setMsicCodeMap(codeMap);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    // Watch for MSIC code changes and update description
    useEffect(() => {
        if (formData.supplier_msic_code && msicCodeMap.has(formData.supplier_msic_code)) {
            const description = msicCodeMap.get(formData.supplier_msic_code);
            const event = {
                target: {
                    name: 'supplier_business_activity_desc',
                    value: description
                }
            };
            handleInputChange(event);
        }
    // Removed handleInputChange from dependencies since it's not needed
    }, [formData.supplier_msic_code, msicCodeMap]);

    return (
        <div className="form-card">
        <h3>Supplier Information</h3>
        
        <div className="form-group">
            <label htmlFor="supplier-name">Supplier Name:</label>
            <input
                type="text"
                id="supplier-name"
                name="supplier_name"
                placeholder="AMS SETIA JAYA SDN. BHD."
                value={formData.supplier_name}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-tin">Supplier Tax ID:</label>
            <input
                type="text"
                id="supplier-tin"
                name="supplier_tin"
                placeholder="C24276483050"
                value={formData.supplier_tin}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-biz-id-type">Supplier ID Type (e.g. SSM, Passport):</label>
            <input
                type="text"
                id="supplier-biz-id-type"
                name="supplier_biz_id_type"
                placeholder="BRN"
                value={formData.supplier_biz_id_type}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-biz-id-value">Supplier ID Value (e.g. SSM num, Passport num):</label>
            <input
                type="text"
                id="supplier-biz-id-value"
                name="supplier_biz_id_value"
                placeholder="202001234567"
                value={formData.supplier_biz_id_value}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-sst-no">Supplier SST No:</label>
            <input
                type="text"
                id="supplier-sst-no"
                name="supplier_sst_no"
                placeholder="A01-2345-67891012"
                value={formData.supplier_sst_no}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-msic-code">Supplier MSIC Code:</label>
            <input
                type="text"
                id="supplier-msic-code"
                name="supplier_msic_code"
                placeholder="Search MSIC Code"
                list="msic-code-datalist"
                value={formData.supplier_msic_code}
                onChange={handleInputChange}
                required
                autoComplete="off"
            />
            <datalist id="msic-code-datalist">
                {Array.from(msicCodeMap).map(([code, description]) => (
                    <option key={code} value={code}>
                        {`${code} - ${description}`}
                    </option>
                ))}
            </datalist>
        </div>

        <div className="form-group">
            <label htmlFor="supplier-business-activity-desc">Supplier Business Activity Description:</label>
            <input
                type="text"
                id="supplier-business-activity-desc"
                name="supplier_business_activity_desc"
                value={formData.supplier_business_activity_desc}
                onChange={handleInputChange}
                readOnly
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-address">Supplier Address:</label>
            <textarea
                id="supplier-address"
                name="supplier_address"
                placeholder="Lot 66, Bangunan Merdeka, Persiaran Jaya, Kuala Lumpur, 50480"
                value={formData.supplier_address}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-state">Supplier State:</label>
            <select
                id="supplier-state"
                name="supplier_state"
                value={formData.supplier_state}
                onChange={handleInputChange}
                required
            >
                <option value="01">Johor</option>
                <option value="02">Kedah</option>
                <option value="03">Kelantan</option>
                <option value="04">Melaka</option>
                <option value="05">Negeri Sembilan</option>
                <option value="06">Pahang</option>
                <option value="07">Pulau Pinang</option>
                <option value="08">Perak</option>
                <option value="09">Perlis</option>
                <option value="10">Selangor</option>
                <option value="11">Terengganu</option>
                <option value="12">Sabah</option>
                <option value="13">Sarawak</option>
                <option value="14">Wilayah Persekutuan Kuala Lumpur</option>
                <option value="15">Wilayah Persekutuan Labuan</option>
                <option value="16">Wilayah Persekutuan Putrajaya</option>
                <option value="17">Not Applicable</option>
            </select>
        </div>

        <div className="form-group">
            <label htmlFor="supplier-city">Supplier City:</label>
            <input
                type="text"
                id="supplier-city"
                name="supplier_city"
                placeholder="PETALING JAYA"
                value={formData.supplier_city}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-country">Supplier Country:</label>
            <input
                type="text"
                id="supplier-country"
                name="supplier_country"
                placeholder="MYS"
                value={formData.supplier_country}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="supplier-tel">Supplier Tel:</label>
            <input
                type="text"
                id="supplier-tel"
                name="supplier_tel"
                placeholder="+60120000000"
                value={formData.supplier_tel}
                onChange={handleInputChange}
                required
            />
        </div>
    </div>
    );
};

export default InvoiceSupplierInfo;
