import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PaymentHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getSessionUserId } = useAuth();
  const hasProcessedPayment = useRef(false);

  useEffect(() => {
    const handlePayment = async () => {
      // Prevent multiple executions
      if (hasProcessedPayment.current) {
        return;
      }

      const timestamp = new Date().toISOString();
      console.log(`[${timestamp}] PaymentHandler mounted - Starting payment processing`);
      
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');
      const userId = getSessionUserId();

      console.log(`[${timestamp}] Payment Details:`, {
        sessionId,
        userId,
        url: window.location.href,
        referrer: document.referrer
      });

      if (!sessionId || !userId) {
        console.error(`[${timestamp}] Missing required parameters:`, {
          hasSessionId: !!sessionId,
          hasUserId: !!userId
        });
        hasProcessedPayment.current = true;
        window.location.href = '/billing';
        return;
      }

      try {
        hasProcessedPayment.current = true;
        
        // send stripe session id to back end for verification and update credits
        const response = await fetch('/api/payment-success', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            session_id: sessionId,
            user_id: userId
          })
        });

        const data = await response.json();
        console.log(`[${timestamp}] Payment verification response:`, {
          success: data.success,
          credits: data.credits_to_add
        });

        // Use window.location.href instead of navigate to force a page refresh
        console.log(`[${timestamp}] Payment processing complete, redirecting to billing page`);
        window.location.href = '/billing';
      } catch (error) {
        console.error(`[${timestamp}] Error processing payment:`, {
          error: error.message,
          stack: error.stack
        });
        // Navigate even on error, using window.location.href to force refresh
        window.location.href = '/billing';
      }
    };

    handlePayment();
  }, [location.search, navigate, getSessionUserId]); // Include all required dependencies

  return (
    <div className="page-container">
      <div className="card" style={{ textAlign: 'center', padding: '2rem' }}>
        <h2>Processing your payment...</h2>
        <p>Please wait while we verify your payment and update your credits.</p>
      </div>
    </div>
  );
};

export default PaymentHandler;
