import React from 'react';

const InvoiceTaxInfo = ({ formData, handleInputChange }) => (
        <div style={{ display:"none" }}>
            <h3>Tax Setting</h3>
        <div className="flexbox">
            <div className="form-group">
            <label htmlFor="global-tax-category">Tax Category:</label>
            <input
                
                className="small"
                type="text"
                id="global-tax-category"
                name="global_tax_category"
                value={formData.global_tax_category}
                onChange={handleInputChange}
                required
            />
        </div>

        <div className="form-group">
            <label htmlFor="global-tax-percentage">Tax Percentage (%):</label>
            <input
                className="small"
                type="number"
                id="global-tax-percentage"
                name="global_tax_percentage"
                value={formData.global_tax_percentage}
                onChange={handleInputChange}
                step="0.01"
                required
            />
        </div>
     </div>

    </div>
);

export default InvoiceTaxInfo;
